import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Toggle } from '../../../../toggle/toggle'
import { getCurrentMarketConfig } from '../../../../../services/market-service'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import './preferences.scss'
import { Label } from '../../../../label/label'
import { TrackedEvent, trackingService } from '../../../../../services/tracking-service'

export const JobDraftPreferences = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()

  const showNationalityOption = getCurrentMarketConfig().order.includeNationalityField
  return (
    <div className="JobDraftPreferences">
      <span className="Popup__title">
        <Label title={t('common.optional')} /> {t('job_draft_details.preferences_title')}
      </span>
      <span className="Popup__subtitle">{t('job_draft_details.preferences_subtitle')}</span>
      <div className="JobDraftPreferences__toggles">
        <div className="JobDraftPreferences__toggles__item">
          <span className="JobDraftPreferences__toggles__item__label">
            {t('job_draft_details.preferences_languages')}
          </span>
          <Toggle
            active={job.languages.use_languages}
            onToggle={() => {
              job.languages.useLanguages(!job.languages.use_languages)
            }}
          />
        </div>
        {getCurrentMarketConfig().order.includeGenderField && (
          <div className="JobDraftPreferences__toggles__item">
            <span className="JobDraftPreferences__toggles__item__label">
              {t('job_draft_details.preferences_gender')}
            </span>
            <Toggle
              active={job.gender.use_gender}
              onToggle={() => {
                job.gender.useGender(!job.gender.use_gender)
              }}
            />
          </div>
        )}
        <div className="JobDraftPreferences__toggles__item">
          <span className="JobDraftPreferences__toggles__item__label">
            {t('job_draft_details.preferences_documents')}
          </span>
          <Toggle
            active={job.documents.use_documents}
            onToggle={() => {
              job.documents.useDocuments(!job.documents.use_documents)
            }}
          />
        </div>
        <div className="JobDraftPreferences__toggles__item">
          <span className="JobDraftPreferences__toggles__item__label">
            {t('job_draft_details.preferences_interview')}
          </span>
          <Toggle
            active={job.manualSelection.use_interview}
            onToggle={() => {
              job.manualSelection.useInterview(!job.manualSelection.use_interview)
            }}
          />
        </div>
        <div className="JobDraftPreferences__toggles__item">
          <span className="JobDraftPreferences__toggles__item__label">
            {t('job_draft_details.preferences_accreditation_required')}
          </span>
          <Toggle
            active={job.accreditation.use_accreditation}
            onToggle={() => {
              job.accreditation.useAccreditation(!job.accreditation.use_accreditation)
            }}
          />
        </div>
        <div className="JobDraftPreferences__toggles__item">
          <span className="JobDraftPreferences__toggles__item__label">
            {t('job_draft_details.preferences_accommodation_offered')}
          </span>
          <Toggle
            active={job.accommodation.use_accommodation}
            onToggle={() => {
              job.accommodation.useAccommodation(!job.accommodation.use_accommodation)
            }}
          />
        </div>
        <div className="JobDraftPreferences__toggles__item">
          <span className="JobDraftPreferences__toggles__item__label">
            {t('job_draft_details.preferences_boarding_offered')}
          </span>
          <Toggle
            active={job.boarding.use_boarding}
            onToggle={() => {
              job.boarding.useBoarding(!job.boarding.use_boarding)
            }}
          />
        </div>
        {showNationalityOption && (
          <div className="JobDraftPreferences__toggles__item">
            <span className="JobDraftPreferences__toggles__item__label">{t('job_draft_details.nationalities')}</span>
            <Toggle
              active={job.nationalities.use_nationalities}
              onToggle={() => {
                if (!job.nationalities.use_nationalities) {
                  trackingService.track(TrackedEvent.OrderFormToggledOnNationalityOption)
                }
                job.nationalities.useNationalities(!job.nationalities.use_nationalities)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
})
