import { Slider } from 'antd'
import './job-draft-price.scss'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../../components/app-layout'
import { StepIndicator } from '../../../../components/step-indicator/step-indicator'
import { ButtonPrimary } from '../../../../components/button/button-primary/button-primary'
import minusImg from '../../../../components/job-draft-details-form/ui/components/staff-amount/assets/minus.svg'
import { Input } from '../../../../components/form/input/input'
import plusImg from '../../../../components/job-draft-details-form/ui/components/assets/plus.svg'
import infoIcon from '../../../../components/sp-rating-popup/assets/info-icon.svg'
import { useMutation, useStore } from '../../../../utils/mst-hooks'
import { routes } from '../../../../routes'
import { useRedirect } from '../../../../services/router'
import { getCurrentMarketConfig } from '../../../../services/market-service'

type InputProps = {
  orderId?: string
  draftJobId?: string
  stepNumber?: number
  showStepIndicator?: boolean
}

export const JobDraftPrice = observer(({ draftJobId, orderId }: InputProps) => {
  const [currentValue, setCurrentValue] = useState(0)
  const [recommendedRate, setRecommendedRate] = useState(0)
  const [minRecommendedRate, setMinRecommendedRate] = useState(0)
  const [maxRecommendedRate, setMaxRecommendedRate] = useState(0)
  const [bottomRate, setBottomRate] = useState(0)
  const [topRate, setTopRate] = useState(0)
  const [totalHours, setTotalHours] = useState(0)
  const [tax, setTax] = useState(0)

  const redirect = useRedirect()
  const { t } = useTranslation()

  const { setRates, getRates } = useStore().data

  const [, setRatesRequest] = useMutation(
    ({ job_id, client_rate }: { job_id: number; client_rate: number }) => setRates({ job_id, client_rate }),
    {
      onSuccess: result => {
        return redirect(routes.orderDraft, {
          orderId: String(orderId),
        })
      },
    },
  )

  const [, getRatesRequest] = useMutation(({ job_id }: { job_id: number }) => getRates({ job_id }), {
    onSuccess: result => {
      setCurrentValue(result.current_client_rate ?? result.recommended_client_rate)
      setRecommendedRate(result.recommended_client_rate)
      setMinRecommendedRate(result.min_recommended_client_rate)
      setMaxRecommendedRate(result.max_recommended_client_rate)
      setBottomRate(result.bottom_client_rate)
      setTopRate(result.top_client_rate)
      setTotalHours(result.total_hours)
      setTax(Number(result.tax))
    },
  })

  useEffect(() => {
    getRatesRequest({ job_id: Number(draftJobId) })
  }, [draftJobId])

  const rateConfig = useMemo(() => {
    return {
      zones: [
        {
          color: '#ff0000',
          min: bottomRate,
          max: minRecommendedRate,
          ogText: t('job_draft_price.low_rate_og_message'),
          fillRateText: t('job_draft_price.low_rate_fill_message'),
          allowed: true,
        },
        {
          color: '#FCB35C',
          min: minRecommendedRate,
          max: recommendedRate,
          ogText: t('job_draft_price.middle_rate_og_message'),
          fillRateText: t('job_draft_price.middle_rate_fill_message'),
          allowed: true,
        },
        {
          color: '#8ECD9C',
          min: recommendedRate,
          max: maxRecommendedRate,
          ogText: t('job_draft_price.good_rate_og_message'),
          fillRateText: t('job_draft_price.good_rate_fill_message'),
          allowed: true,
        },
        {
          color: '#5EAA6F',
          min: maxRecommendedRate,
          max: topRate,
          ogText: t('job_draft_price.good_rate_og_message'),
          fillRateText: t('job_draft_price.good_rate_fill_message'),
          allowed: true,
        },
      ],
      marks: {
        [minRecommendedRate]: {
          label: (
            <div className="custom-mark-start">
              {t('job_draft_price.money_amount', {
                currency: getCurrentMarketConfig().currency,
                amount: minRecommendedRate,
              })}
            </div>
          ),
        },
        [recommendedRate]: {
          label: (
            <div className="custom-mark-end">
              {t('job_draft_price.money_amount', {
                currency: getCurrentMarketConfig().currency,
                amount: recommendedRate,
              })}
            </div>
          ),
        },
      },
      recommendedRate,
      minRecommendedRate,
      maxRecommendedRate,
      bottomRate,
      topRate,
    }
  }, [recommendedRate, minRecommendedRate, maxRecommendedRate, bottomRate, topRate])

  const gradientParts = rateConfig.zones
    .map(zone => {
      return [
        `${zone.color} ${((zone.min - rateConfig.bottomRate) / (rateConfig.topRate - rateConfig.bottomRate)) * 100}%`,
        `${zone.color} ${((zone.max - rateConfig.bottomRate) / (rateConfig.topRate - rateConfig.bottomRate)) * 100}%`,
      ]
    })
    .flat()
    .join(', ')

  const gradientBackground = `linear-gradient(to right, ${gradientParts})`
  const currentZone = rateConfig.zones.find(zone => zone.min <= currentValue && zone.max > currentValue) ?? {
    color: currentValue < rateConfig.bottomRate ? '#ff0000' : '#5EAA6F',
    ogText:
      currentValue < rateConfig.bottomRate
        ? t('job_draft_price.rate_is_less_than_min', { min: rateConfig.bottomRate })
        : t('job_draft_price.good_rate_og_message'),
    fillRateText:
      currentValue < rateConfig.bottomRate
        ? t('job_draft_price.rate_is_less_than_min', { min: rateConfig.bottomRate })
        : t('job_draft_price.good_rate_fill_message'),
  }

  return (
    <div className="JobDraftLocation__page">
      <Header title={'Price'} showBack />
      <div className="JobDraftLocation">
        <Separator.Vertical height={35} />
        <StepIndicator stepsCount={5} currentStepNumber={4} />
        <span className="JobDraftCategory__title">{t('job_draft_price.title')}</span>
        <span className="JobDraftCategory__subtitle">{t('job_draft_price.subtitle')}</span>
        <Separator.Vertical height={24} />
        <div className="JobDraftCategory__controls JobDraftCategory__slider">
          <div className="JobDraftPrice__rateInput">
            <div className="JobDraftPrice__rateInput__wrapper">
              <div className="JobDraftPrice__rateInput__row">
                <Separator.Spacer />
                <div
                  onClick={() => {
                    setCurrentValue(value => {
                      const newValue = Number((value - 0.5).toFixed(2))
                        if (newValue < rateConfig.bottomRate) {
                            return rateConfig.bottomRate
                        }

                        return newValue
                    })
                  }}
                  title={t('job_draft_price.decrease_rate')}
                  className={`JobDraftPrice__rateInput__row__button`}
                  style={{ backgroundImage: `url(${minusImg})` }}
                />
                <Separator.Horizontal width={18} />
                <Input
                  label={t('job_draft_price.input_label', { currency: getCurrentMarketConfig().currency })}
                  value={String(currentValue)}
                  type="number"
                  handleChange={value => setCurrentValue(Number(value))}
                  className={`JobDraftPrice__rateInput__row__input`}
                />
                <Separator.Horizontal width={18} />
                <div
                  onClick={() => {
                    setCurrentValue(value => {
                      return Number((value + 0.5).toFixed(2))
                    })
                  }}
                  className={`JobDraftPrice__rateInput__row__button`}
                  title={t('job_draft_price.increase_rate')}
                  style={{ backgroundImage: `url(${plusImg})` }}
                ></div>
                <Separator.Spacer />
              </div>
              <Slider
                  defaultValue={minRecommendedRate}
                  min={rateConfig.bottomRate}
                  max={rateConfig.topRate}
                  step={0.5}
                  tooltip={{
                    formatter: value =>
                        t('job_draft_price.money_amount', { currency: getCurrentMarketConfig().currency, amount: value }),
                  }}
                  trackStyle={{ backgroundColor: 'transparent' }}
                  railStyle={{ background: gradientBackground }}
                  value={currentValue}
                  onChange={setCurrentValue}
                  marks={rateConfig.marks}
              />
              <div className="JobDraftPrice__rateInput__labels">
                <span className="JobDraftPrice__rateInput__label" style={{ color: currentZone.color }}>
                  {currentZone.ogText}
                </span>
                <span className="JobDraftPrice__rateInput__label" style={{ color: currentZone.color }}>
                  {currentZone.fillRateText}
                </span>
              </div>
              <div className="JobDraftPrice__totalContainer">
                <span className="JobDraftPrice__total__title">{t('job_draft_price.estimated_total')}</span>
                <span className="JobDraftPrice__total__value">{t('job_draft_price.money_amount', { currency: getCurrentMarketConfig().currency, amount: ((totalHours * currentValue) + (totalHours * currentValue) * tax).toFixed(2) })}</span>
              </div>
              <span className="JobDraftPrice__total__note">{t('job_draft_price.includes_vat', { vat: tax * 100 })}</span>
            </div>
          </div>
        </div>
        <div className="JobDraftPrice__alert">
          <img src={infoIcon} />
          <span className="JobDraftPrice__alert__text">{t('job_draft_price.short_explanation')}</span>
        </div>
        <Separator.Vertical height={50} />
        <ButtonPrimary
          text={t('job_draft_details.save')}
          action={() => {
            setRatesRequest({
              job_id: Number(draftJobId),
              client_rate: currentValue,
            })
          }}
          disabled={currentValue < rateConfig.bottomRate}
        />
      </div>
    </div>
  )
})
