import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const JobModel = types.model('Job', {
  id: types.identifierNumber,
  title: types.string,
  order_id: types.number,
  work_category_id: types.number,
  quantity: types.number,
  instructions: types.string,
  start_date: types.string,
  end_date: types.string,
  uniform_provided: types.string,
  job_status: types.string,
  job_status_id: types.number,
  price: types.number,
  currency: types.string,
  hours: types.number,
  hourly_rate: types.number,
  rate_type: types.string,
  is_automatic_checkout: types.number,
  commission: types.number,
  order_name: types.string,
  work_category_name: types.string,
  shifts_count: types.string,
  description: types.string,
  event_description: types.string,
  fulfilment_rate: types.number,
  no_of_completed_shifts: types.number,
  client: types.model({
    name: types.string,
    image_url: types.string,
  }),
  is_client_rates_allowed: types.optional(types.boolean, false),
  recommended_client_rate: types.optional(types.maybeNull(types.number), null),
  min_recommended_client_rate: types.optional(types.maybeNull(types.number), null),
  max_recommended_client_rate: types.optional(types.maybeNull(types.number), null),
  bottom_client_rate: types.optional(types.maybeNull(types.number), null),
  top_client_rate: types.optional(types.maybeNull(types.number), null),
})

export const getFormForJobForm = (
  params: JobForm | (Omit<JobForm, 'order_id'> & { id: number }),
): Record<string, unknown> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const form: Record<string, any> = {
    filter: {},
    intervals: {},
  }

  for (const [field, value] of Object.entries(params)) {
    if (field === 'languages') {
      form.filter.languages = params.languages?.length ? params.languages : null
    } else if (field === 'gender') {
      form.filter.gender = params.gender
    } else if (field === 'certificates') {
      form.filter.certificates = params.certificates?.length ? params.certificates : null
    } else if (field === 'work_requirement_ids') {
      form.work_requirement_ids = params.work_requirement_ids ?? null
    } else if (field === 'intervals') {
      for (const [day, { start_time, end_time, selected }] of params.intervals) {
        if (selected) {
          form.intervals[day] = { start_time, end_time }
        }
      }
    } else if (field === 'nationalities') {
      form.filter.nationalities = params.nationalities?.length ? params.nationalities : null
      form.filter.ethnicities = params.ethnicities?.length ? params.ethnicities : null
    } else {
      form[field] = value
    }
  }

  return form
}

export const jobActions = (self: DataStore) => ({
  createJob: flow(function* (params: JobForm) {
    const form = getFormForJobForm(params)
    return (yield self.request('post', `client/order/jobs/post`, form)) as JobSnapshot
  }),

  updateJob: flow(function* (params: Omit<JobForm, 'order_id'> & { id: number }) {
    const form = getFormForJobForm(params)
    return (yield self.request('post', 'client/order/jobs/update', form)) as JobSnapshot
  }),

  removeJob: flow(function* (params: { jobId: number }) {
    self.jobs.delete(String(params.jobId))
    yield self.request('post', `client/order/jobs/delete`, { job_id: params.jobId })
  }),

  setRates: flow(function* ({ job_id, client_rate }: { job_id: number; client_rate: number }) {
    yield self.request('post', `client/order/jobs/set-rate`, {
      job_id,
      client_rate,
    })
  }),

  getRates: flow(function* ({ job_id }: { job_id: number }) {
    return (yield self.request('post', `client/order/jobs/get-rate`, {
      job_id,
    })) as {
      recommended_client_rate: number
      min_recommended_client_rate: number
      max_recommended_client_rate: number
      bottom_client_rate: number
      top_client_rate: number
      current_client_rate: number | null
      total_hours: number
      tax: number | null
    }
  }),
})

export const jobViews = (self: DataStore) => ({
  getJobsForOrder(orderId: number | undefined) {
    if (!orderId) return []
    return Array.from(self.jobs.values()).filter(job => job.order_id === orderId)
  },

  get jobsList() {
    return Array.from(self.jobs.values())
  },
})

export type Job = typeof JobModel.Type
export type JobSnapshot = typeof JobModel.SnapshotType

export enum BoardingStatus {
  noBoarding = 0,
  halfBoarding = 1,
  fullBoarding = 2,
}

export type JobForm = {
  order_id?: number
  work_category_id: number
  quantity: number
  client_location_id: number
  public_transport_accessibility: number | null
  contact_user_id?: number
  start_date: string
  end_date: string
  is_recurring: number // 0 | 1
  work_requirement_ids?: number[]
  instructions: string
  seniority_level: number
  uniform_id: number
  invited_sp_ids?: number[]
  languages?: number[]
  nationalities?: number[]
  ethnicities?: number[]
  gender: number | null // null - not selected, 1 - male, 2 - female, 3 - mixed
  certificates?: number[]
  intervals: Map<
    string,
    {
      start_time: string
      end_time: string
      selected: boolean
    }
  >
  is_automatic_checkout: number // 0 | 1
  is_accreditation_required: number // 0 | 1
  is_interview_required: number // 0 | 1
  assignment_type: number // 1 - invitation/client confirmation, 2 - (default) auto-accept
  is_accommodation_offered: number
  boarding_type: BoardingStatus
}
