import { useState } from 'react'
import { observer } from 'mobx-react'

import { Collapsible } from '../../../../../../../components/collapsible/collapsible'
import './schedule-item.scss'
import dropdownRightIcon from '../../../../../../../components/assets/arrow-dropdown-right.svg'
import dropdownDownIcon from '../../../../../../../components/assets/arrow-dropdown-down.svg'
import locationIcon from './assets/location-icon.svg'
import { WorkCategorySchedule } from './work-category-schedule'
import { GroupedLocationItem } from '../../../../../../../models/schedule'

type ScheduleItemProps = {
  locationSchedule: GroupedLocationItem
  firstWeekDate: Date
  weekDates: Date[]
}

function ScheduleItemComponent({ locationSchedule, firstWeekDate, weekDates }: ScheduleItemProps) {
  const [collapsed, setCollapsed] = useState(false)
  const rowsNum = locationSchedule.work_categories.reduce((sum, item) => sum + item.quantity, 0)

  return (
    <div className="ScheduleItem">
      <div className="ScheduleItem__header">
        <img
          onClick={() => setCollapsed(!collapsed)}
          src={collapsed ? dropdownRightIcon : dropdownDownIcon}
          className="ScheduleItem__header__arrow"
        />
        <img src={locationIcon} className="ScheduleItem__header__icon" />
        <span className="ScheduleItem__header__title">{locationSchedule.location_name}</span>
      </div>
      <Collapsible collapsed={collapsed} maxHeight={92 * rowsNum + 350}>
        {locationSchedule.work_categories.map((workCategorySchedule, key) => (
          <WorkCategorySchedule
            key={key}
            workCategorySchedule={workCategorySchedule}
            firstWeekDate={firstWeekDate}
            weekDates={weekDates}
          />
        ))}
      </Collapsible>
    </div>
  )
}

export const ScheduleItem = observer(ScheduleItemComponent)
