import { load, save } from './storage'
import { config } from '../config/env'

export const CODE_UAE = 'uae'
export const CODE_GR = 'gr'
export const CODE_SA = 'sa'

export const LANGUAGE_EN = 'en'

export const ENABLED_MARKET_CODES = [CODE_UAE, CODE_GR, CODE_SA]

export const CODE_UAE_ISO2 = 'ae'
export const CODE_GR_ISO2 = 'gr'
export const CODE_SA_ISO2 = 'sa'

export const ENABLED_MARKET_CODES_ISO2: { [key: string]: string } = {
  [CODE_UAE]: CODE_UAE_ISO2,
  [CODE_GR]: CODE_GR_ISO2,
  [CODE_SA]: CODE_SA_ISO2,
}

class MarketService {
  language: string | null = null

  getCurrentMarketCode() {
    const availableMarkets = ENABLED_MARKET_CODES.join('|')
    const regExp = new RegExp(`/(${availableMarkets})(/{0,1}.*)`)
    const marketMatch = window.location.pathname.match(regExp)

    if (Boolean(marketMatch)) {
      save('marketId', marketMatch?.[1])
      return marketMatch?.[1]
    }

    const marketId = load('marketId')
    if (!marketId) {
      save('marketId', CODE_UAE)
    }

    window.location.href = `/${marketId}${window.location.pathname}${window.location.search}`
    return null
  }

  getCurrentLanguage() {
    if (this.language === null) {
      this.language = load('userLanguage') as string | null
    }

    return this.language
  }
}

export const marketService = new MarketService()

type MarketConfigType = {
  iso2Code: string
  centerCoordinatesOnMap: {
    lat: number
    lng: number
  }
  currency: string
  launched: boolean
  stripePublishedKey: string
  registration: {
    allowOnlyCorporate: boolean
    requireConsentAcceptance: boolean
    requiredCorporateFields: string[]
  }
  order: {
    nonExperiencedTitle: string
    includePublicTransportationField: boolean
    includeGenderField: boolean
    includeNationalityField: boolean
  }
  finance: {
    showInvoices: boolean
  }
  applicantReview: {
    includeNationality: boolean
    includeHeight: boolean
  }
  firstDayOfWeek: string
}
export const marketConfig: { [key: string]: MarketConfigType } = {
  [CODE_UAE]: {
    iso2Code: CODE_UAE_ISO2,
    centerCoordinatesOnMap: {
      lat: 24,
      lng: 53.8478,
    },
    currency: 'AED',
    launched: true,
    stripePublishedKey: config.STRIPE_PUBLISHABLE_KEY_UAE,
    registration: {
      allowOnlyCorporate: false,
      requireConsentAcceptance: false,
      requiredCorporateFields: ['company_name', 'address', 'po_box', 'is_lpo_required', 'trade_name'],
    },
    order: {
      nonExperiencedTitle: 'job_draft_details.experience_student',
      includePublicTransportationField: true,
      includeGenderField: true,
      includeNationalityField: true,
    },
    finance: {
      showInvoices: true,
    },
    applicantReview: {
      includeNationality: true,
      includeHeight: true,
    },
    firstDayOfWeek: 'Mon',
  },
  [CODE_GR]: {
    iso2Code: CODE_GR_ISO2,
    centerCoordinatesOnMap: {
      lat: 39.0988,
      lng: 21.838,
    },
    currency: 'EUR',
    launched: true,
    stripePublishedKey: config.STRIPE_PUBLISHABLE_KEY_GR,
    registration: {
      allowOnlyCorporate: true,
      requireConsentAcceptance: true,
      requiredCorporateFields: [
        'company_name',
        'industry',
        'address',
        'po_box',
        'license_number',
        'free_zone',
        'region',
        'vat',
      ],
    },
    order: {
      nonExperiencedTitle: 'job_draft_details.experience_beginner',
      includePublicTransportationField: false,
      includeGenderField: false,
      includeNationalityField: false,
    },
    finance: {
      showInvoices: false,
    },
    applicantReview: {
      includeNationality: false,
      includeHeight: false,
    },
    firstDayOfWeek: 'Mon',
  },
  [CODE_SA]: {
    iso2Code: CODE_SA_ISO2,
    centerCoordinatesOnMap: {
      lat: 25.16919,
      lng: 46.77095,
    },
    currency: 'SAR',
    launched: true,
    stripePublishedKey: config.STRIPE_PUBLISHABLE_KEY_UAE, // TODO replace with actual key
    registration: {
      allowOnlyCorporate: true,
      requireConsentAcceptance: false,
      requiredCorporateFields: ['company_name', 'address', 'po_box', 'is_lpo_required', 'trade_name'],
    },
    order: {
      nonExperiencedTitle: 'job_draft_details.experience_student',
      includePublicTransportationField: true,
      includeGenderField: true,
      includeNationalityField: true,
    },
    finance: {
      showInvoices: true,
    },
    applicantReview: {
      includeNationality: true,
      includeHeight: true,
    },
    firstDayOfWeek: 'Sun',
  },
}

export const getCurrentMarketConfig = (): MarketConfigType => {
  return marketConfig[marketService.getCurrentMarketCode() ?? CODE_UAE]
}

export const isCurrentMarketLaunched = () => {
  const market = marketService.getCurrentMarketCode()

  return marketConfig[market ?? CODE_UAE].launched
}
