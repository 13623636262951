import { observer } from 'mobx-react'

import { SpShiftStatus } from '../../../../../../../models/schedule/types'
import { Schedule, ScheduleDate, ScheduleShiftSnapshot, ShiftStatus } from '../../../../../../../models/schedule/types'
import { SpTime } from './sp-time'
import { SpCanceled } from './sp-canceled'
import './sp-cell.scss'

export function SpCellComponent({
  jobSchedule,
  defaultSchedule,
  spId,
  shift,
  dateShift,
  spName = null,
  showEmptySlot = false
}: {
  jobSchedule: Schedule
  defaultSchedule: Schedule
  spId: number
  shift: ScheduleShiftSnapshot | null
  dateShift: ScheduleDate | null
  spName?: string | null
  showEmptySlot?: boolean
}) {
  if (!shift) return null

  const spShift = jobSchedule.getSpShift({ spId, shiftId: shift.id }) ?? null
  const hasSp = dateShift?.sps.has(String(spId)) && spShift

  return (
    <div className="SpCell">
      {hasSp ? (
        spShift.status.id === SpShiftStatus.canceled ? (
          <SpCanceled shift={shift} spShift={spShift} />
        ) : (
          <SpTime jobSchedule={jobSchedule} spId={spId} shift={shift} spShift={spShift} spName={spName} showEmptySlot={showEmptySlot} />
        )
      ) : shift.status === ShiftStatus.Started || shift.status === ShiftStatus.Upcoming ? (
        <SpTime jobSchedule={defaultSchedule} spId={spId} shift={shift} spShift={spShift} spName={spName} showEmptySlot={showEmptySlot} />
      ) : null}
    </div>
  )
}

export const SpCell = observer(SpCellComponent)
